<template>
  <div class="regulations-container">
    <div class="regulations-container-bread" style="font-size:0.14rem;padding-top:0.38rem">
      <div class="tab-secondNav" v-if="secondNav.length>0" style="margin-bottom:0.2rem">
        <ul class="tab-secondNav-choose-item">
          <li
              v-for="(item, i) in secondNav"
              :key="i"
              :class="{ tabActivesecondNav: cur2 == item.id }"
              @click="changeSecondNav(item,i)"
          >
            {{ item.name }}
            <span :class="{tabActivesecondNavSpan: cur2 == item.id}">
                <span :class="{tabActivesecondNavSubSpan: cur2 == item.id}">

                </span>
            </span>
          </li>
        </ul>

      </div>
      <div class="tab-subSwitch" v-if="subTypeList.length > 0">
        <!-- <div class="tab-subSwitch-title">分类：</div> -->
        <ul class="tab-subSwitch-choose-item">
          <li
            v-for="(item, i) in subTypeList"
            :key="i"
            :class="{
              tabActive: cur == item.id,
              imgType: item.label_name.length > 4,
            }"
            @click="changeTab(item.id, i)"
          >
            {{ item.label_name }}
          </li>
        </ul>
      </div>
      <!-- 本馆概况 / <span style="color:#761F1E">工作年报</span> -->
    </div>
    <div class="regulations-container-pic">
      <img
        src="https://image.bookgo.com.cn/webculture/jm/profile/profile-pic1.png"
        alt=""
      />
    </div>
    <commonList :list="regulationList" :type="'1'"/>
    <!-- 分页组件 -->
    <pagination
      :total="total"
      :page.sync="page"
      :limit.sync="limit"
      @pagination="getList"
      v-if="regulationList.length > 0"
    />
  </div>
</template>

<script>
import commonList from "../components/commonList";
import Pagination from "@/components/Pagination";
import { list } from "../../api/article";
import {navChildList} from "@/api/header/header";
import moment from "moment";
export default {
  data() {
    return {
      page: 1,
      limit: 10,
      total: 0,
      id: "",
      aid: process.env.VUE_APP_AID,
      regulationList: [],
      secondNav:[],
      cur2:0,
      cur:0,
      cur3:0,
      column_id:"",
      cid:"",
      subTypeList:[],
    };
  },
  components: { commonList, Pagination },
  computed: {
    totalPage() {
      return Math.ceil(this.total / this.limit);
    },
  },
  created() {},
  mounted() {
      window.scroll(0, 0);
      this.column_id = this.$route.query.column_id;
      this.cid = this.$route.query.cid;
      if(this.cid){
          this.cur2 = this.cid;
      }
    this.getList();
    this.getChildNav()
  },
  watch:{
      $route(newV) {
      window.scroll(0, 0);
      this.page = 1;
      this.cur = 0;
      this.column_id = newV.query.column_id;
      this.cid = newV.query.cid;
      if (this.cid) {
        this.cur2 = this.cid;
      }
      this.secondNav = [];
      this.subTypeList = [];
      this.getList();
      this.getChildNav();
    },
  },
  methods: {
    changeSecondNav(item) {
      //获取导航下的分类
      if (item.url_type == 1 && item.url) {
        window.open(item.url, "_blank");
      } else {
        if (item.content_id == 0) {
            this.$router.push(
                `/${item.tpl_code}?id=${this.$route.query.id}&cid=${item.id}&column_id=${item.column_id}`
              );
        //     let routeUrl = this.$router.resolve({
        //     path: `/${item.tpl_code}`,
        //     query: {
        //       id: this.$route.query.id,
        //       cid: item.id,
        //       column_id: item.column_id,
        //     },
        //   });
        //   window.open(routeUrl.href, "_blank");
        } else {
          this.$router.push(
                `/${item.tpl_code}/detail?tid=${item.content_id}&id=${this.$route.query.id}&cid=${item.id}&column_id=${item.column_id}`
              );
        //   let routeUrl = this.$router.resolve({
        //     path: `/${item.tpl_code}/detail`,
        //     query: {
        //       tid: item.content_id,
        //       id: this.$route.query.id,
        //       cid: item.id,
        //       column_id: item.column_id,
        //     },
        //   });
        //   window.open(routeUrl.href, "_blank");
        }
      }
    },
    getChildNav() {
      let params = {
        aid: this.aid,
        pid: this.$route.query.id,
        app_type: 1,
      };

      navChildList(params).then((res) => {
        console.log(res);
        // this.secondNav = res.data
        if (res.code == 0) {
          this.secondNav = res.data;
        //   console.log("secondNav", this.secondNav);
        }
      });
    },
    changeTab(id) {
      this.page = 1;
      this.cur = id;
      this.getList();
    },
    getList() {
      let params = {
        aid: this.aid,
        page: this.page,
        page_size: this.limit,
        app_type: 1,
        column_id: this.column_id,
      };
      if (parseInt(this.cur) > 0) {
        params.label_id = this.cur;
      }
      console.log(params);

      this.listLoading = true;
      list(params).then((res) => {
        console.log(res);
        this.listLoading = false;
        this.regulationList = res.data.list;
        res.data.list.forEach((item) => {
          item.publish_time = moment(item.publish_time * 1000).format(
            "YYYY年MM月DD日"
          );
        });
        if (res.data.label_list.length > 0) {
          this.subTypeList = res.data.label_list;
          this.subTypeList.unshift({
            id: 0,
            label_name: "全部",
          });
        }
        this.total = res.data.count;
      });
    },
  },
};
</script>

<style lang="scss">
@import "../../assets/scss/cmsCommon_pdf_index.scss";
.tab-secondNav-choose-item{
  font-size: 0.17rem;
  border-bottom: 1px solid #D5D5D5;
  padding-bottom: 0.14rem;
}
.tab-secondNav-choose-item{
  display: flex;
}
.tab-secondNav-choose-item li{
  margin-left: 10px;
}
.tab-secondNav-choose-item li{
  cursor: pointer;
}
.tab-secondNav-choose-item li:not(:first-child){
  cursor: pointer;
  margin-left: 0.46rem;
}
.tabActivesecondNav{
  color: #761F1E;
  position: relative;
}

.tabActivesecondNavSpan{
  width: 100%;
  height: 3px;
  display: inline-block;
  position: absolute;
  bottom: -0.14rem;
  left: 0rem;
}
.tabActivesecondNavSubSpan{
  width: 80%;
  background: #991C10;
  height: 3px;
  display: inline-block;
  position: absolute;
  animation: 0px;
  margin: auto;
  left: 0px;
  right: 0px;
}
.tab-subSwitch{
  margin-top: 0.33rem;
}
.news-container{
  padding-top: 0.38rem;
}
</style>
